<template>
    <ViewTitle title="獎項查詢" />
    <AwardFilter
        :isLoading="isLoadingTable"
        @show-data-table="showDataTable"
        v-model:filterPayloadProp="filterPayload"
        v-model:filterLogProp="filterLog"
        v-model:apiPermission="apiPermission"
    />
    <div v-if="filterLog.contestId?.value">
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="true"
            :isLoading="isLoadingTable"
            :isHasListData="true"
        />
    </div>
    <div v-if="!isLoadingTable && isSelf" class="table-info">
        <div
            v-for="(info, idx) in infoList"
            :key="idx"
            :class="info.class"
            class="info"
        >
            <div class="title">{{ info.title }}</div>
            <div class="value">
                {{ info.value }}
                <Button
                    v-if="info.class === 'monthly-fyn'"
                    buttonStyle="blue"
                    buttonWord="查看明細"
                    :disabled="!info.modalValue"
                    @click="openModal(info.modalValue)"
                />
            </div>
        </div>
    </div>
    <Modal :title="modalData.title" v-model="modalData.isShow">
        <template #modal-content>
            <div class="url">
                競賽辦法請參考IFPA官網：
                <a href="https://www.ifpa.org.tw/award" target="_blank">
                    https://www.ifpa.org.tw/award
                </a>
            </div>
            <div class="detail-table" :class="{ 'no-gap': !modalData.showGap }">
                <div class="table-row table-header">
                    <div class="month">工作月</div>
                    <div class="fyn">競賽件數</div>
                    <div class="gap">
                        {{ modalData.showGap ? '下一階差距件數' : '' }}
                    </div>
                </div>
                <div
                    v-for="(data, key) in modalData.details"
                    :key="key"
                    class="table-row"
                >
                    <div class="month">{{ key }}</div>
                    <div class="fyn">{{ data.total }} 件</div>
                    <div class="gap">
                        {{ modalData.showGap ? data.gap + ' 件' : '' }}
                    </div>
                </div>
            </div>
        </template>
        <template #modal-bottom>
            <Button
                buttonWord="關閉"
                buttonStyle="grey"
                @click="modalData.isShow = false"
            />
        </template>
    </Modal>
    <p
        v-if="listData.length > 0 && !isLoadingTable && contestNow === 'group'"
        class="table-tip"
        :class="{ isSelf }"
    >
        <span>*</span>
        尚未加上LQA跟天健獎分數，再請自行加總
    </p>
    <Dropdown
        v-if="modeOption.length > 0 && isShowModeOption"
        title="競賽明細"
        :options="modeOption"
        v-model="mode"
        class="mode-filter"
    />
    <AwardTable
        :listData="transListData"
        :isLoading="isLoadingTable"
        :filterLog="filterLog"
        :contestNow="contestNow"
        :mode="mode"
        :infos="isSelf ? infoList : []"
        @open-modal="openModal"
    />
</template>
<script>
import { lazyLoadComponent } from '@/assets/javascripts/util'

import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import AwardFilter from '@/containers/award/AwardsFilter.vue'
import AwardTable from '@/containers/award/AwardsTable.vue'
const SearchCondition = lazyLoadComponent('components/SearchCondition')
import Dropdown from '@/components/Dropdown.vue'
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal.vue'
import {
    awardsMode,
    awardSetting,
    awardInfoSetting
} from '@/assets/javascripts/award/setting'

export default {
    name: 'Awards',
    components: {
        ViewTitle,
        AwardFilter,
        AwardTable,
        SearchCondition,
        Dropdown,
        Button,
        Modal
    },
    methods: {
        async showDataTable() {
            this.isLoadingTable = true
            this.$showLoading()
            const p = this.filterPayload
            this.updateContestNow(p.contestId)
            this.isSelf = p.relationMode === 'self'
            let apiAlias = this.isSelf
                ? this.apiPermission.self
                : this.apiPermission.bmGuideGroup
            const routeArr = apiAlias.split('/')
            apiAlias = routeArr[routeArr.length - 1]

            try {
                const relationMode = this.isSelf ? 'self' : 'org'
                const res = await this.$getResponse(
                    p,
                    {
                        apiAlias,
                        apiName: '此報表'
                    },
                    awardSetting[p.contestId].api[relationMode]
                )
                this.listData = res.data
            } catch (e) {
                this.listData = []
            } finally {
                this.isLoadingTable = false
                this.$hideLoading()
            }
        },
        updateContestNow(contestId) {
            this.contestNow = awardSetting[contestId].value
        },
        openModal(val, showGap = true) {
            const details = {}
            _.keys(val).forEach((key) => {
                details[key] = {
                    total: val[key].total || val[key].fyn || 0,
                    gap: val[key].nextRankGap || val[key].gap || 0
                }
            })
            this.modalData.details = details
            this.modalData.isShow = true
            this.modalData.showGap = showGap
        }
    },
    computed: {
        searchConditionList() {
            const contestId = this.filterLog.contestId?.value
            return [{ title: '競賽名稱', value: awardSetting[contestId]?.name }]
        },
        infoList() {
            const list = []
            const info = this.listData.qualifies
            if (!info) return list

            const contestId = this.filterLog.contestId.value
            awardSetting[contestId].info.forEach((data) => {
                const title = awardInfoSetting[data].title
                const unit = awardInfoSetting[data].unit
                const subUnit = awardInfoSetting[data].subUnit
                list.push({
                    title,
                    value: `${this.$numberWithComma(
                        info[data]?.total || 0
                    )} ${unit} | 距離 ${this.$numberWithComma(
                        info[data]?.nextRankGapCount || 0
                    )} ${unit} ${subUnit}達標`
                })
            })

            const extraInfo = {
                fynPerMonth: {
                    title: awardInfoSetting.fynPerMonth?.title,
                    class: 'monthly-fyn',
                    modalValue: info.fynPerMonth?.details
                }
            }
            list.push({
                title: '目前等級',
                value: this.listData.rank || '尚無等級',
                class: 'rank'
            })
            awardSetting[contestId].extraInfo?.forEach((data) => {
                list.push(extraInfo[data])
            })
            return list
        },
        modeOption() {
            if (!this.isSelf || this.isLoadingTable) return []
            return awardsMode[this.contestNow] || []
        },
        transListData() {
            let data = []
            if (this.mode) {
                data = this.listData[this.mode] || []
            } else if (this.isSelf) {
                data = this.listData.insurances || []
            } else {
                data = this.listData.members || []
            }
            return data
        },
        isShowModeOption() {
            let result = false
            awardsMode[this.contestNow].some((mode) => {
                result = !!this.listData[mode.value]
                return result
            })
            return result
        }
    },
    watch: {
        modeOption: {
            handler() {
                if (this.modeOption.length > 0) {
                    this.mode = this.modeOption[0].value
                } else {
                    this.mode = ''
                }
            },
            deep: true
        },
        contestNow(val) {
            if (val === 'gold') {
                this.modalData.title = '每月件數明細'
            }
        }
    },
    data() {
        return {
            isLoadingTable: false,
            filterPayload: {
                contestType: '',
                contestId: '',
                contestRule: '',
                year: '',
                dataType: '',
                relationMode: '',
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                endDate: ''
            },
            filterLog: {},
            listData: [],
            contestNow: '',
            isSelf: false,
            apiPermission: {},
            mode: '',
            modalData: {
                isShow: false,
                details: {}
            }
        }
    }
}
</script>
<style scoped lang="scss">
.condition-wrapper {
    :deep(.pc) {
        display: none !important;
    }
    :deep(.mobile) {
        padding-bottom: 10px;
    }
}
.table-info {
    font-size: 14px;
    color: $sixth-black;
    position: relative;
    margin-bottom: 20px;
    .info {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;
        .title {
            &:after {
                content: ' ：';
            }
        }
        &.rank {
            position: absolute;
            top: 0;
            right: 0;
        }
        &.monthly-fyn {
            align-items: center;
            .value {
                display: flex;
                align-items: center;
                :deep(.button) {
                    padding: 3px 5px;
                    height: fit-content;
                    border-radius: 5px;
                    margin-left: 5px;

                    .button-word {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        font-size: 12px;
        margin-bottom: 15px;

        .info {
            justify-content: space-between;
            .title {
                &:after {
                    content: '';
                }
            }

            .value {
                text-align: right;
                :deep(.button) {
                    width: 75px;
                    .button-word {
                        line-height: 30px;
                    }
                }
            }
            &.rank {
                position: static;
            }
        }
    }
}

.table-tip {
    margin: 20px 0;
    font-size: 14px;
    color: $placeholder-black;
    span {
        color: $fourth-red;
    }
    &.isSelf {
        margin-top: -15px;
    }

    @media (max-width: 576px) {
        font-size: 12px;
        margin-top: 0;
    }
}

.mode-filter {
    display: flex;
    width: fit-content;
    margin-bottom: 10px;
    :deep(.form-label) {
        margin-right: 10px;
    }
    :deep(.select-container) {
        input {
            background-color: $primary-white;
        }
        .option-container {
            z-index: 6;
        }
    }

    @media (max-width: 576px) {
        width: auto;
        justify-content: space-between;
    }
}

.modal {
    :deep(.modal-content) {
        padding-bottom: 30px;
        .url {
            color: $sixth-black;
            font-weight: 700;
            margin-bottom: 17px;
        }

        .detail-table {
            font-size: 14px;
            .table-row {
                display: flex;
                justify-content: space-between;
                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                }
            }
            .table-header {
                font-weight: 500;
            }

            .month {
                width: 100px;
            }
            .fyn {
                width: 70px;
            }
            .gap {
                width: 100px;
            }
            @media (max-width: 576px) {
                &.no-gap {
                    .gap {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
